<template>
    <div class="two" style="width:700px;margin:auto;">
        <el-form label-width="140px" :model="form" ref="vform">
            <el-form-item label="主要作品：">
                <div style="color:#CB0000;">含评论文章、重要演出、获奖情况、教学、辅导、译著及有关艺术的主要工作（请详细填写并注明时间，或上传附件）</div>
                <el-radio-group v-model="form.work_type" text-color="#CC0000" @change="form.works=null">
                    <el-radio label="input">直接填写</el-radio>
                    <el-radio label="file">上传附件</el-radio>
                </el-radio-group>
                <el-input v-model="form.works" type="textarea" :rows="8" resize="none" v-if="form.work_type=='input'" placeholder="请填写您的主要作品"></el-input>
                <Upload @change="getworks" v-else :imgUrl="form.works"/>
            </el-form-item>
            <el-form-item label="从事艺术工作简历：">
                <div style="color:#CB0000;">请详细填写并注明时间，或上传附件</div>
                <el-radio-group v-model="form.resume_type" text-color="#CC0000" @change="form.resume=null">
                    <el-radio label="input">直接填写</el-radio>
                    <el-radio label="file">上传附件</el-radio>
                </el-radio-group>
                <el-input v-model="form.resume" type="textarea" :rows="8" resize="none" v-if="form.resume_type=='input'" placeholder="请填写您的从事艺术工作简历"></el-input>
                <Upload @change="getresume" v-else :imgUrl="form.resume"/>
            </el-form-item>
        </el-form>
        <div class="t-center fx_around" style="width:500px;margin:auto">
            <div class="btns1" @click="Previous_step">上一步</div>
            <div class="btns" @click="next_step">下一步</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form:{
                work_type:'input',
                resume_type:'input',
                resume:'',
                works:''
            },
        };
    },
    props:{
        forms:{
            default:null,
            type:Object
        }
    },
    watch:{
        forms:{  
            handler:function(val){ 
                if(JSON.stringify(val)!='{}'){
                    this.form = val
                    return
                }
            },  
            immediate:true,//关键
            deep:true
        },
    },
    created() {
        if(localStorage.getItem('threeFrom')){
            this.form = JSON.parse(localStorage.getItem('threeFrom'))
        }
    },
    mounted() {

    },
    methods: {
        getworks(res){
            this.form.works = res
        },
        getresume(res){
            this.form.resume = res
        },
        Previous_step(){
            this.$emit('change',0)
        },
        next_step(){
            this.$refs["vform"].validate((valid) => {
                if (valid) {
                    localStorage.setItem('threeFrom',JSON.stringify(this.form))
                    this.$emit('change',1)
                }
            });
        },
    }
};
</script>

<style scoped lang="scss">
    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    ::v-deep .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    ::v-deep .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 420px;
        height: 160px;
        line-height: 160px;
        text-align: center;
        border-radius: 50%;
    }
    ::v-deep .avatar {
        width: 420px;
        height: 160px;
        display: block;
    }
</style>
