<template>
    <div class="" style="width:500px;margin:auto;">
        <el-form label-width="100px" ref="vform" :model="form" :rules="rules">
            <el-form-item label="证件类型：" prop="card_type">
                    <el-select v-model="form.card_type" placeholder="请选择证件类型">
                    <el-option  v-for="(item,i) in Status"  :key="i"  :label="item.name"  :value="item.type"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="证件号：" prop="card_no">
                <el-input v-model="form.card_no" placeholder="请输入您的证件号"></el-input>
            </el-form-item>
            <el-form-item label="证件照：" prop="card_front">
                <div>
                    <div style="color:#FF0000;">要求：请上传10MB以内的 .jpg/gif/bmp/png/jpeg 图片</div>
                    <Upload :accept="'image/*'" @change="getFront" :imgUrl="form.card_front" :uploadSize="true"/>
                    <div class="t-center" style="width:260px;">证件正面</div>
                    <!-- <Upload @change="getBack" :accept="'image/*'" :uploadSize="true"/>
                    <div class="t-center" style="width:260px;">证件反面</div> -->
                </div>
            </el-form-item>
            <el-form-item label="" prop="card_back">
                <Upload @change="getBack" :accept="'image/*'" :imgUrl="form.card_back" :uploadSize="true"/>
                <div class="t-center" style="width:260px;">证件反面</div>
            </el-form-item>
        </el-form>
        <div class="t-center fx_around" style="width:500px;margin:auto">
            <div class="btns" @click="next_step">下一步</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            Status:[
                {name:'国内身份证',type:1},
                {name:'非国内身份证',type:2},
            ],
            form:{
                card_back:'',
                card_front:''
            },
            rules:{
                card_type:{ required: true, message: "请选择证件类型", trigger: "change" },
                card_no:{ required: true, message: "请输入您的证件号", trigger: "change" },
                card_front:{ required: true, message: "请上传证件照正面", trigger: "change" },
                card_back:{ required: true, message: "请上传证件照反面", trigger: "change" },
            },
        };
    },
    props:{
        forms:{
            default:null,
            type:Object
        }
    },
    watch:{
        forms:{  
            handler:function(val){ 
                if(JSON.stringify(val)!='{}'){
                    this.form = val
                    return
                }
            },  
            immediate:true,//关键
            deep:true
        },
    },
    created() {
        if(localStorage.getItem('oneFrom')){
            console.log(localStorage.getItem('oneFrom'))
            this.form = JSON.parse(localStorage.getItem('oneFrom'))
        }
    },
    mounted() {

    },
    methods: {
        next_step(){
            this.$refs["vform"].validate((valid) => {
                if (valid) {
                    localStorage.setItem('oneFrom',JSON.stringify(this.form))
                    this.$emit('change',1)
                }
            });
        },
        getFront(res){
       
            this.form.card_front = res
        },
        getBack(res){
            console.log(res)
            this.form.card_back = res
        }
    }
};
</script>

<style scoped lang="scss">
    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    ::v-deep .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    ::v-deep .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 260px;
        height: 160px;
        line-height: 160px;
        text-align: center;
        border-radius: 50%;
    }
    ::v-deep .avatar {
        width: 260px;
        height: 160px;
        display: block;
    }
</style>
